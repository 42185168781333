import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCampaignDetails, storeCampaignDetails } from '../Helper/campaignUtils';

const UTMSourceContext = createContext();

export const UTMSourceProvider = ({ children }) => {
  const [utmSource, setUtmSource] = useState(null);

  useEffect(() => {
    const { utm_source } = getCampaignDetails();
    const urlParams = new URLSearchParams(window.location.search);
    const utmFromUrl = urlParams.get('utm_source');

    const finalUtmSource = utm_source || utmFromUrl;

    if (finalUtmSource) {
      setUtmSource(finalUtmSource);
      storeCampaignDetails({ utm_source: finalUtmSource });
      if (!utmFromUrl) {
        urlParams.set('utm_source', finalUtmSource);
        const updatedUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', updatedUrl);
      }
    }
  }, []);

  return <UTMSourceContext.Provider value={utmSource}>{children}</UTMSourceContext.Provider>;
};

export const useUTMSource = () => useContext(UTMSourceContext);
