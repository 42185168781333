import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Skeleton } from '@mui/material';
import { Star } from '@mui/icons-material';

const ReviewCardSkeleton = () => (
  <Box className="card-container">
    <Card className="review-card-container">
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={12} md={7}>
            <Box className="review-card-content-box">
              <Box className="star-content-box">
                <Star />
                <Skeleton variant="text" width={50} height={25} />
              </Box>
              {Array.from({ length: 3 }).map((_, index) => (
                <Box className="outline-container" key={index} mr={1}>
                  <Skeleton className="outline-text" variant="text" width={40} height={20} />
                </Box>
              ))}
            </Box>
            <Box
              className="review-heading"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}>
              <Typography variant="h4">
                <Skeleton variant="text" width={100} height={20} />
              </Typography>
              <Typography>
                <Skeleton variant="text" width={250} height={65} />
              </Typography>

              <Skeleton variant="text" width={60} height={20} />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              mt={{ md: '27px' }}
              fontFamily="inherit">
              <Skeleton variant="circular" width={36} height={36} />
              <Box>
                <Typography fontSize={13} fontWeight={700} lineHeight="20px" color="#000000">
                  <Skeleton variant="text" width={60} height={30} />
                </Typography>
                <Typography fontSize={12} fontWeight={400} lineHeight="20px" color="#00000099">
                  <Skeleton variant="text" width={40} height={20} />
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={12} md={5}>
            <Box className="image-slider-container">
              <Skeleton
                variant="rectangular"
                width={350}
                height={200}
                sx={{ borderRadius: '6px' }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Box>
);

export default ReviewCardSkeleton;
