import React from 'react';
import { Button, Box } from '@mui/material';

const CustomButton = ({ children, className, showArrow = false, ...rest }) => (
  <>
    <Button
      {...rest}
      sx={{ textTransform: 'none', fontWeight: 700, fontSize: '20px' }}
      className={className}
      endIcon={
        showArrow ? (
          <Box
            component="img"
            src="./icons/button-end.svg"
            sx={{ width: 26, height: 26 }}
            alt="detail"
          />
        ) : null
      }>
      <Box
        className="everyStep_explore_btn_inner"
        style={{
          display: 'flex',
          gap: '20px',
          alignItems: 'center',
          fontSize: '13px'
        }}>
        {children}
      </Box>
    </Button>
  </>
);

export default CustomButton;
