import React, { useRef, useState } from 'react';
import './PackageTabsCard.css';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Skeleton,
  Typography
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
// import LocationIcon from '../../../assets/PackageDetails/location.svg';
import PackageSlider from '../package_slider/PackageSlider';

const PackageTabsCard = ({ tourHighLights, tourItiernaryData, isLoader }) => {
  const [expanded, setExpanded] = useState('panel-highlights');
  const accordionRefs = useRef({});

  if (!tourItiernaryData || typeof tourItiernaryData !== 'object') {
    return null;
  }

  const highlightKeys = Object.keys(tourHighLights).filter(
    (key) => key.startsWith('Title-') || key.startsWith('txt-') || key.startsWith('Img-')
  );

  const highlights = [];
  for (let i = 0; i < highlightKeys.length / 3; i++) {
    const titleKey = `Title-${i + 1}`;
    const txtKey = `txt-${i + 1}`;
    const imgKey = `Img-${i + 1}`;

    if (tourHighLights[titleKey] && tourHighLights[txtKey] && tourHighLights[imgKey]) {
      highlights.push({
        title: tourHighLights[titleKey],
        description: tourHighLights[txtKey],
        img: tourHighLights[imgKey]
      });
    }
  }

  const handleChange = (panel, index) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    if (isExpanded && accordionRefs.current[panel]) {
      const accordionElement = accordionRefs.current[panel];
      let scrollOffset = 0;
      if (index === 2) {
        scrollOffset = window.innerHeight * 0.98;
      } else if (index > 1) {
        scrollOffset = window.innerHeight * 0.25;
      }
      const accordionRect = accordionElement.getBoundingClientRect();
      const accordionTopPosition = accordionRect.top + window.scrollY;
      const targetScrollPosition = accordionTopPosition - scrollOffset;
      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };

  return (
    <Box className="accordion-card-sec">
      <Accordion
        ref={(el) => (accordionRefs.current['panel-highlights'] = el)}
        expanded={expanded === 'panel-highlights'}
        onChange={handleChange('panel-highlights', 0)}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel-highlights-content"
          id="panel-highlights-header">
          <Box className="title">Tour Highlights</Box>
        </AccordionSummary>
        <AccordionDetails>
          {highlights.map((highlight, index) => (
            <Box key={index} mb={2}>
              <Typography className="title" mb={1}>
                {isLoader ? (
                  <Skeleton variant="text" width="20%" height="100%" />
                ) : (
                  <>{highlight.title}</>
                )}
              </Typography>
              <Typography paragraph className="description">
                {isLoader ? (
                  <Skeleton variant="text" width="80%" height={100} />
                ) : (
                  <>{highlight.description}</>
                )}
              </Typography>
              <Box className="slider">
                {isLoader ? (
                  <Skeleton variant="text" width="20%" height={100} />
                ) : (
                  <PackageSlider images={[highlight.img]} title={highlight.title} />
                )}
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      {Object.keys(tourItiernaryData).map((key, index) => {
        const dayData = tourItiernaryData[key];
        if (!dayData || !dayData.Title) return null;

        return (
          <Accordion
            key={key}
            ref={(el) => (accordionRefs.current[`panel${index}`] = el)}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`, index)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}>
              <Box className="title" sx={{ fontFamily: 'inherit' }}>
                {isLoader ? (
                  <Skeleton variant="text" width="20%" height="100%" />
                ) : (
                  <>
                    {`${key.replace('Day', 'Day ')} : ${dayData?.Title || ''} ${
                      dayData?.Attractions ? dayData.Attractions : ''
                    } ${dayData?.number_of_night ? `(${dayData?.number_of_night} Nights)` : ''}`}
                  </>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="description">
                {isLoader ? (
                  <Skeleton variant="text" width="80%" height={100} />
                ) : (
                  <> {dayData.Description}</>
                )}
              </Box>
              {/* {dayData.Attractions && (
                <Box className="location-sec">
                  <img src={LocationIcon} alt="location-icon" />
                  <Typography>
                    {isLoader ? (
                      <Skeleton variant="text" width="80%" height={100} />
                    ) : (
                      <> {dayData.Attractions}</>
                    )}
                  </Typography>
                </Box>
              )} */}
              {dayData.Img && (
                <Box className="slider">
                  {isLoader ? (
                    <Skeleton variant="text" width="20%" height={100} />
                  ) : (
                    <PackageSlider highLightsData={tourHighLights} />
                  )}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default PackageTabsCard;
