/* eslint-disable no-unused-vars */
import React from 'react';
import './WhyKesariSelect.css';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
// import { StarRateRounded } from '@mui/icons-material';
// import KesariLogo from '../../../assets/Home/image_kesari.png';
// import TripadvisorLogo from '../../../assets/Home/image_tripadvisor.png';
// import GoogleLogo from '../../../assets/Home/image_google.png';

const MotionBox = motion.create(Box);

const itemVariants = {
  hidden: { opacity: 0, translateY: 30 },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut'
    }
  },
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3
    }
  }
};

const WhyKesariSelect = ({ ratingCount, whyKesariData, isLoader }) => (
  // const getImage = (company) => {
  //   switch (company) {
  //     case 'kesari':
  //       return KesariLogo;
  //     case 'tripadvisor':
  //       return TripadvisorLogo;
  //     case 'google':
  //       return GoogleLogo;
  //     default:
  //       return null;
  //   }
  // };
  // const ratings = ratingCount && typeof ratingCount === 'object' ? Object.entries(ratingCount) : [];

  <Box className="cmn-pl-pr">
    <Box className="why-kesari-title" sx={{ mb: { xs: '10px', sm: '10px' } }}>
      <Typography
        className="why-kesari-head"
        sx={{ fontSize: { xs: '22px', sm: '22px', md: '26px', lg: '32px', xl: '44px' } }}>
        Why choose Kesari Select?
      </Typography>
    </Box>

    <Box className="why-kesari-main" sx={{ marginTop: { sm: '0px', md: '20px', lg: '20px' } }}>
      <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
        {isLoader
          ? [1, 2, 3, 4].map((_, index) => (
              <Grid item xs={6} sm={6} md={3} key={index}>
                <Box className="why-kesari-title_box">
                  <Skeleton variant="rectangular" width="100%" height={60} />
                </Box>
              </Grid>
            ))
          : whyKesariData?.length > 0 &&
            whyKesariData.map((item, index) => {
              const titleKey = `title_${index + 1}`;
              const titleValue = item[titleKey];
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  key={index}
                  className="vertical-dash-line"
                  sx={{
                    pl: { xs: '0px', sm: '0px' },
                    pr: { xs: '50px', sm: '50px' }
                  }}>
                  <MotionBox variants={itemVariants} whileHover="hover">
                    <Box className="why-kesari-title_box">
                      <Typography className="why-kesari-title_text">{titleValue}</Typography>
                    </Box>
                  </MotionBox>
                </Grid>
              );
            })}
      </Grid>
    </Box>
    {/* <Box className="hero_rating_section" sx={{ mt: 3 }}>
        <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
          <ul>
            {isLoader
              ? Array.from(new Array(3)).map((_, index) => (
                  <li key={index} className="banner_ratting_single">
                    <Skeleton variant="text" width={100} height={30} />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width={80} height={20} />
                  </li>
                ))
              : ratings.map(([company, rating]) => (
                  <li key={company} className="banner_ratting_single">
                    <Box className="ratting_group">
                      <Box className="ratting_text">&#x2004;{rating || 0}</Box>
                      <StarRateRounded sx={{ color: 'gold' }} />
                    </Box>
                    <Box className="ratting_group company_group">
                      <img src={getImage(company)} alt={company} />
                      <Box className="ratting_company_text">
                        {company.charAt(0).toUpperCase() + company.slice(1)}
                      </Box>
                    </Box>
                  </li>
                ))}
          </ul>
        </Grid>
      </Box> */}
  </Box>
);
export default WhyKesariSelect;
