import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './EveryStep.css';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  FormGroup,
  Grid,
  Skeleton,
  Stack,
  Switch,
  styled,
  Tab,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { BASE_URL, API_URL } from '../../../APIs/endpoint';
import { api } from '../../../APIs/api';
import { domesticMapData, internationalMapData } from '../../../constants/everyStepsConstant';
import { convertToHyphenatedLowerCase, determineZone } from '../../../Helper/global';
import { toast } from 'react-toastify';
import worldMap from '../../../assets/Home/Every_Step/World_Map.webp';
import indiaMap from '../../../assets/Images/Vector.webp';
import ActiveTabIcon from '../../../assets/Home/active-icon.webp';
import ActiveTabIconHorizontal from '../../../assets/Home/active-icon-horizontal.webp';
import CustomButton from '../../common/Button';
import ContinentCardSkeleton from '../../Loader/ContinentCardSkeleton';

const DefaultImgUrl = process.env.PUBLIC_URL + '/images/DefaultImg.jpg';

const checkImageURL = (url) => {
  const regex = /packages\/.*\s/;
  return regex.test(url) || url === null ? DefaultImgUrl : url;
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: '65.45px',
  height: '28.8px',
  padding: 0,
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    width: '45px',
    height: '20px'
  },
  '& .MuiSwitch-thumb': {
    width: '20.94px',
    height: '20.94px',
    borderRadius: 12,
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    transition: theme.transitions.create(['width'], {
      duration: 500
    }),
    color: 'rgba(228, 30, 37, 1)',
    [theme.breakpoints.down('sm')]: {
      width: '12px',
      height: '12px'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    transition: 'transform 0.3s ease-in-out',
    '&.Mui-checked': {
      transform: 'translateX(36px)',
      color: 'rgba(228, 30, 37, 1)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff'
      },
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(26px)'
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: '24px',
    color: 'rgba(228, 30, 37, 1)',
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#ffffff',
    boxSizing: 'border-box'
  }
}));

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

const EveryStep = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [internationalActiveValue, setInternationalValue] = useState(2);
  const [selectedContinent, setSelectedContinent] = useState('');
  const [isDomestic, setIsDomestic] = useState(false);
  const [domesticActiveValue, setDomesticValue] = React.useState(0);
  const [previousDomesticValue, setPreviousDomesticValue] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [continentErpData, setContinentErpData] = useState();

  const getPadding = () => {
    if (isMobile) return 2;
    if (isTablet) return 10;
    return 1;
  };

  const handleDomesticChange = (event, newValue) => {
    setPreviousDomesticValue(newValue);
    setDomesticValue(newValue);
  };

  const handleInternationalChange = (event, newValue) => {
    setInternationalValue(newValue);
  };

  const handleChecked = () => {
    setIsDomestic(!isDomestic);
  };

  const getContinentLabel = () => {
    if (isDomestic) {
      const selectedDomestic = domesticMapData.find((item) => item.value === domesticActiveValue);
      return selectedDomestic ? selectedDomestic.label.replace('India', '').trim() : 'Unknown';
    } else {
      const selectedInternational = internationalMapData.find(
        (item) => item.value === internationalActiveValue
      );
      return selectedInternational ? selectedInternational.label : 'Unknown';
    }
  };

  const getContinentErpListApi = async (continent) => {
    try {
      setSelectedContinent(continent);
      setIsLoader(true);
      setContinentErpData(null);
      const payload = {
        tour_category: isDomestic ? 'Domestic' : 'International',
        [isDomestic ? 'zone' : 'continent']: continent
      };
      const endpoint = isDomestic
        ? `${BASE_URL}${API_URL.GET_ZONE_WISE_LIST}?tour_category=${payload.tour_category}&zone=${payload.zone}`
        : `${BASE_URL}${API_URL.GET_CONTINENT_LIST}?tour_category=${payload.tour_category}&continent=${payload.continent}`;

      const erpResponse = await api.get(endpoint);
      const { status, data, message } = erpResponse;
      if (status) {
        const uniqueData = getUniqueRecords(data);
        setContinentErpData(uniqueData);
      } else {
        toast.error(message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  const getUniqueRecords = (data) => {
    const uniqueRecords = new Map();
    if (isDomestic) {
      data.forEach((item) => {
        if (item.State && !uniqueRecords.has(item.State)) {
          uniqueRecords.set(item.State, item);
        }
      });
    } else {
      data.forEach((item) => {
        if (item.country && !uniqueRecords.has(item.country)) {
          uniqueRecords.set(item.country, item);
        }
      });
    }
    return Array.from(uniqueRecords.values()).slice(0, 8);
  };

  useEffect(() => {
    const continent = getContinentLabel();
    getContinentErpListApi(continent);
  }, [isDomestic, domesticActiveValue, internationalActiveValue]);

  const transformData = (data) =>
    data &&
    data.map((item) => ({
      ...item,
      img: item.package_image,
      place: item.country || item.Country
    }));

  const getUniqueStates = (data) => {
    const uniqueStates = [];
    const statesSet = new Set();
    data &&
      data.forEach((item) => {
        if (!statesSet.has(item.state || item.State)) {
          statesSet.add(item.state || item.State);
          uniqueStates.push(item);
        }
      });
    return uniqueStates.slice(0, 8);
  };

  const shuffleArray = (array) => array && array.sort(() => Math.random() - 0.5);
  const cardMenu = transformData(continentErpData && continentErpData);
  const shuffledCardMenu = shuffleArray(cardMenu);
  const domesticCards = isDomestic ? getUniqueStates(shuffledCardMenu) : shuffledCardMenu;
  const random8Cards = domesticCards && domesticCards.slice(0, 8);

  const handleCardMedia = (media) => {
    if (!media) {
      return;
    }
    let url = '';
    const country = media?.Country || media?.country;
    const state = media?.State || media?.state;
    if (country?.toLowerCase() === 'india') {
      const zone = determineZone(state);
      url = `/explore/india/${zone}/${convertToHyphenatedLowerCase(state)}`;
    } else {
      url = `/explore/world/${convertToHyphenatedLowerCase(
        selectedContinent || ''
      )}/${convertToHyphenatedLowerCase(country)}`;
    }
    if (url) {
      navigate(url);
    }
  };

  const handleExploreRedirect = (isDomestic, selectedContinent) => {
    const basePath = isDomestic ? 'india' : 'world';
    const url = `/explore/${basePath}/${convertToHyphenatedLowerCase(selectedContinent)}`;
    navigate(url, {
      state: {
        tourCategory: isDomestic ? 'Domestic' : 'International',
        [isDomestic ? 'zone' : 'continent']: selectedContinent
      }
    });
  };

  const InternationalMap = useCallback(
    () => (
      <Box className="PerfectWeekend_map_navigation internation-tab">
        <Container>
          <Box
            sx={{
              width: '100%',
              typography: 'body1',
              position: 'relative'
            }}>
            <TabContext value={internationalActiveValue}>
              <Box
                sx={{
                  borderBottomStyle: 'dashed !important',
                  borderWidth: '2px !important',
                  borderColor: '#ffffff !important',
                  opacity: '40%',
                  zIndex: 1,
                  position: 'absolute',
                  top: 35,
                  left: 0,
                  right: 0
                }}
              />
              <Box
                sx={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '100%' },
                  overflowX: { xs: 'auto' }
                }}>
                <TabList
                  onChange={handleInternationalChange}
                  aria-label="internationalMap tabs"
                  indicatorColor="white"
                  sx={{
                    '& .MuiTabs-scroller': {
                      overflowX: 'auto !important'
                    }
                  }}>
                  {internationalMapData.map((item, idx) => (
                    <Tab
                      key={idx}
                      icon={
                        <img
                          className="every-step-map-img"
                          src={ActiveTabIconHorizontal}
                          alt={item.label}
                          width={40}
                          height={52}
                        />
                      }
                      {...a11yProps(item.value)}
                      label={
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          gap={1}
                          zIndex={2}
                          height={{ xs: 50, md: 120 }}
                          className="hover-effect">
                          <img
                            className="every-step-map-img"
                            src={item.icon}
                            alt={item.label}
                            width="auto"
                            height="60%"
                            style={{
                              opacity: idx !== internationalActiveValue ? 0.3 : 1,
                              maxHeight: '60%',
                              width: 'auto',
                              objectFit: 'contain'
                            }}
                          />
                          <Typography
                            fontSize={{ xs: 12, md: 14 }}
                            sx={{
                              fontFamily:
                                idx !== internationalActiveValue ? 'gothamBook' : 'gothamBold',
                              color: '#ffffff',
                              textTransform: 'capitalize',
                              opacity: idx !== internationalActiveValue ? 0.3 : 1
                            }}>
                            {item.label}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </TabList>
              </Box>
              <Box
                sx={{
                  marginTop: '42px',
                  paddingLeft: { xs: '10px' },
                  paddingRight: { xs: '10px' }
                }}></Box>
            </TabContext>
          </Box>
        </Container>
      </Box>
    ),
    [internationalActiveValue]
  );

  const DomesticMap = useCallback(
    () => (
      <Box className="PerfectWeekend_map_navigation domestic-tab">
        <Container>
          <Box sx={{ width: '100%', typography: 'body1', position: 'relative' }}>
            <TabContext value={domesticActiveValue}>
              <Box
                sx={{
                  borderBottomStyle: 'dashed !important',
                  borderWidth: '2px !important',
                  borderColor: '#ffffff !important',
                  opacity: '40%',
                  zIndex: 1,
                  position: 'absolute',
                  top: 35,
                  left: 0,
                  right: 0
                }}
              />
              <Box
                sx={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  display: 'flex',
                  justifyContent: 'center',
                  width: { xs: '100%' },
                  overflowX: { xs: 'auto' }
                }}>
                <TabList
                  onChange={handleDomesticChange}
                  aria-label="domesticMap tabs"
                  indicatorColor="white"
                  sx={{
                    '& .MuiTabs-scroller': {
                      overflowX: 'auto !important'
                    }
                  }}>
                  {domesticMapData.map((item, idx) => (
                    <Tab
                      sx={{ marginRight: 12 }}
                      key={idx}
                      {...a11yProps(item.value)}
                      label={
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          zIndex={2}
                          className="hover-effect">
                          <Box
                            component="img"
                            width={40}
                            height={40}
                            src={ActiveTabIcon}
                            sx={{
                              visibility: domesticActiveValue !== idx && 'hidden',
                              mb: 2,
                              animation:
                                idx === domesticActiveValue
                                  ? `slideIn 0.5s ease-out`
                                  : idx === previousDomesticValue
                                  ? `slideOut 0.5s ease-in`
                                  : `none`
                            }}
                          />
                          <Typography
                            fontSize={{ xs: 12, md: 14 }}
                            sx={{
                              fontFamily: idx !== domesticActiveValue ? 'gothamBook' : 'gothamBold',
                              textTransform: 'capitalize',
                              color: '#ffffff',
                              opacity: domesticActiveValue !== idx && 0.3
                            }}>
                            {item.label}
                          </Typography>
                        </Box>
                      }
                    />
                  ))}
                </TabList>
              </Box>
              <Box
                sx={{
                  marginTop: '42px',
                  paddingLeft: { xs: '10px' },
                  paddingRight: { xs: '10px' }
                }}
              />
            </TabContext>
          </Box>
        </Container>
      </Box>
    ),
    [domesticActiveValue]
  );

  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <Box overflow="hidden" className="everystep_main">
      <Box className="everyStep_world">
        <img src={isDomestic ? indiaMap : worldMap} alt="" />
      </Box>
      <Box className="everystep_heading">
        <Grid container className="everystep_heading_container">
          <Grid item xs={12} sm={12} md={8} lg={12} xl={12}>
            <Box className="everystep_heading_title">
              {isDomestic ? (
                <Typography>Find magic in your backyard.</Typography>
              ) : (
                <Typography>Every step is a passport stamp to a new adventure</Typography>
              )}
            </Box>
            <Typography className="everystep_heading_desc">
              Your dream vacation is just a map away!
            </Typography>

            <Grid
              item
              xs={2}
              sm={2}
              md={8}
              lg={12}
              xl={8}
              sx={{
                marginTop: { xs: 6 },
                width: 'fit-content',
                margin: '0 auto'
              }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={12}
                  xl={8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    width: 'fit-content',
                    margin: '0 auto'
                  }}>
                  <FormGroup>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography className={!isDomestic ? 'domestic' : 'internationl'}>
                        Domestic
                      </Typography>
                      <AntSwitch
                        onClick={(e) => handleChecked(e)}
                        defaultChecked
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                      <Typography className={!isDomestic ? 'internationl' : 'domestic'}>
                        International
                      </Typography>
                    </Stack>
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!isDomestic ? <InternationalMap /> : <DomesticMap />}

      <Container sx={{ paddingX: { xs: '20px', md: '20px' }, overflowX: 'auto' }}>
        {isLoader ? (
          <ContinentCardSkeleton isTablet={isTablet} isMobile={isMobile} />
        ) : random8Cards && random8Cards.length > 0 ? (
          <Box className="visiting_places">
            <Container sx={{ paddingX: '0px !important' }}>
              <Grid container spacing={2} justifyContent="center">
                {random8Cards
                  .slice()
                  .sort((a, b) =>
                    (isDomestic ? a.State : a.country)?.localeCompare(
                      isDomestic ? b.State : b.country,
                      'en',
                      { sensitivity: 'base' }
                    )
                  )
                  .map((item, i) => (
                    <Grid
                      className="visiting_places_items"
                      item
                      xs={3}
                      sm={3}
                      md={4}
                      lg={3}
                      key={i}>
                      <Card
                        sx={{
                          display: { xs: 'flex' },
                          gap: { xs: '20px' },
                          width: '100%',
                          marginTop: 5,
                          overflow: 'unset',
                          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'scale(0.90)',
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)'
                          }
                        }}>
                        <CardActionArea
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                          }}
                          onClick={() => handleCardMedia(item)}>
                          <CardMedia
                            sx={{
                              width: '95%',
                              height: isMobile ? '150px' : '220px',
                              textAlign: 'center',
                              marginTop: '-12px',
                              borderRadius: 2,
                              transition: 'transform 0.3s ease-in-out'
                            }}
                            component="img"
                            height="170px"
                            image={checkImageURL(item.package_image)}
                            alt="Image"
                          />
                          <CardContent
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: getPadding()
                            }}>
                            <Typography className="everyStep_card_placeName">
                              {isLoader ? (
                                <Skeleton variant="text" width={80} height={30} />
                              ) : (
                                <>{truncateText(isDomestic ? item.State : item.country, 16)}</>
                              )}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography className="erp-no-data-found-text">
              {isLoader ? (
                <Skeleton variant="rectangular" width="100%" height={200} />
              ) : (
                <>{'Coming Soon...!'}</>
              )}
            </Typography>
          </Box>
        )}
      </Container>

      <Box className="everyStep_btn_container">
        <CustomButton
          className="everyStep_typo_btn"
          onClick={() => handleExploreRedirect(isDomestic, selectedContinent)}
          showArrow={true}>
          <Typography fontFamily="gothamBold">Explore with Us</Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default EveryStep;
