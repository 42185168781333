import React from 'react';
import { Box, Button } from '@mui/material';
import './DreamVacationCard.css';
import { useNavigate } from 'react-router-dom';

const DreamVacationCard = ({ packageDetails }) => {
  const navigate = useNavigate();
  const handleEnquiryNow = () => {
    const countryName =
      packageDetails?.country === 'India' ? packageDetails?.state : packageDetails?.country;
    const packageId = packageDetails?.new_item_code;
    sessionStorage.setItem(
      'tripDetails',
      JSON.stringify({
        countryName: countryName,
        packageId: packageId
      })
    );
    navigate(`/vacation-spot`, {
      state: {
        packageId: packageId,
        selectedTour: countryName
      }
    });
  };

  return (
    <Box className="dream-vacation-card">
      <Box className="title">Dream Vacation : 3 Steps away !</Box>
      <Box className="steps">
        <ul>
          <li>
            <Box className="number">01</Box>
            <Box className="subtitle">Describe your dream Trip</Box>
          </li>
          <li>
            <Box className="number">02</Box>
            <Box className="subtitle">
              Tell us your Requirements & we will get back to you with the customizations
            </Box>
          </li>
          <li>
            <Box className="number">03</Box>
            <Box className="subtitle">Book the Trip</Box>
          </li>
        </ul>
      </Box>
      <Box className="btn-container">
        <Button className="customize-tour-btn" variant="contained" onClick={handleEnquiryNow}>
          Enquire Now
        </Button>
      </Box>
    </Box>
  );
};

export default DreamVacationCard;
