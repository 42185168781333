import React from 'react';
import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';

const CustomTabsData = ({
  packageInclusionData,
  packageExclusionData,
  policyAndTermsData,
  isLoader
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const createMarkup = (htmlString) => ({ __html: htmlString });

  return (
    <Box p={isSmallScreen ? 2 : 4}>
      {packageInclusionData && (
        <>
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={200} />
          ) : (
            <Box mb={4}>
              <Typography variant="h6" fontWeight="bold" fontFamily="inherit" gutterBottom>
                Inclusions
              </Typography>
              <Box
                sx={{ fontFamily: 'inherit' }}
                dangerouslySetInnerHTML={createMarkup(packageInclusionData)}
              />
            </Box>
          )}
        </>
      )}

      {packageExclusionData && (
        <>
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={200} />
          ) : (
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Exclusions
              </Typography>
              <Box
                sx={{ fontFamily: 'inherit' }}
                dangerouslySetInnerHTML={createMarkup(packageExclusionData)}
              />
            </Box>
          )}
        </>
      )}

      {policyAndTermsData && (
        <>
          {isLoader ? (
            <Skeleton variant="text" width="100%" height={200} />
          ) : (
            <Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Policy and Terms
              </Typography>
              <Box
                sx={{ fontFamily: 'inherit' }}
                dangerouslySetInnerHTML={createMarkup(policyAndTermsData)}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomTabsData;
