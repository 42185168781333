import React, { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useUTMSource } from '../context/UTMSourceProvider';
import { MainLayout } from '../layout/MainLayout';
import { Authlayout } from '../layout/Authlayout';
import Home from '../pages/HomeMain';
import PackageDetails from '../pages/PackageDetails';
import PackageListing from '../pages/PackageListing';
import ProductReview from '../pages/ProductReview/ProductReview';
import ReviewDetails from '../pages/ReviewDetails';
import ReviewPay from '../components/ReviewPay/ReviewPay';
import ReviewAndPay from '../pages/ReviewAndPay';
import Payment from '../pages/payment/payment';
import PhotoGallery from '../pages/PhotoGallery/PhotoGallery';
import TripRequestConfirmation from '../pages/TripRequestConfirmation';
import Conversational from '../pages/Conversational';
import SelectedVacationSpot from '../components/conversational/SelectedVacationSpot/SelectedVacationSpot';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndCondition from '../pages/TermsAndCondition';
import NotFound404 from '../pages/NotFound404';

const authRoutes = [
  { path: '/', element: <Home /> },
  { path: '/product-review', element: <ProductReview /> },
  { path: '/explore/product-details/:package-id', element: <PackageDetails /> },
  { path: '/review-details', element: <ReviewDetails /> },
  { path: '/review-pay', element: <ReviewPay /> },
  { path: '/explore/*', element: <PackageListing /> },
  { path: '/conversational', element: <Conversational /> },
  { path: '/vacation-spot', element: <SelectedVacationSpot /> },
  { path: '/review-pay', element: <ReviewAndPay /> },
  { path: '/payment', element: <Payment /> },
  { path: '/photo-gallery', element: <PhotoGallery /> },
  { path: '/trip-confirmation', element: <TripRequestConfirmation /> }
];

// Custom hook for UTM persistence in navigation
const useUTMPersistentNavigate = () => {
  const navigate = useNavigate();
  const utmSource = useUTMSource();

  return (to, options) => {
    if (typeof to === 'string' && utmSource) {
      const url = new URL(to, window.location.origin);
      const params = new URLSearchParams(url.search);

      if (!params.get('utm_source')) {
        params.set('utm_source', utmSource);
        to = `${url.pathname}?${params.toString()}`;
      }
    }
    navigate(to, options);
  };
};

// Component to append utm_source dynamically
const RouteWithUTMSource = ({ element, utmSource, navigate }) => {
  useEffect(() => {
    if (utmSource) {
      const currentPathname = window.location.pathname;
      const currentSearchParams = new URLSearchParams(window.location.search);

      if (!currentSearchParams.get('utm_source')) {
        currentSearchParams.set('utm_source', utmSource);
        const updatedUrl = `${currentPathname}?${currentSearchParams.toString()}`;

        if (window.location.href !== window.location.origin + updatedUrl) {
          navigate(updatedUrl, { replace: true });
        }
      }
    }
  }, [utmSource, navigate]);

  return element;
};

// Recursive function to append UTM handling to all routes
const appendUTMSourceToRoutes = (routes, utmSource, navigate) =>
  routes.map((route) => {
    if (route.path === '/vacation-spot') {
      return { ...route };
    }

    return {
      ...route,
      element: (
        <RouteWithUTMSource element={route.element} utmSource={utmSource} navigate={navigate} />
      ),
      children: route.children
        ? appendUTMSourceToRoutes(route.children, utmSource, navigate)
        : undefined
    };
  });

const Routes = () => {
  const utmSource = useUTMSource();
  const navigate = useUTMPersistentNavigate();

  const enhancedRoutes = appendUTMSourceToRoutes(
    [
      {
        path: '/',
        element: <Authlayout />,
        children: authRoutes
      },
      {
        path: '/auth',
        element: <MainLayout />,
        children: []
      },
      {
        path: '/termsncondition',
        element: <TermsAndCondition />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
      },
      { path: '*', element: <NotFound404 /> }
    ],
    utmSource,
    navigate
  );

  return useRoutes(enhancedRoutes);
};

export default Routes;
