import { configureStore } from '@reduxjs/toolkit';
import packageListReducer from '../slices/packageListingSlice';
import metaDataListReducer from '../slices/metaDataSlice';

export default configureStore({
  reducer: {
    packageList: packageListReducer,
    metaDataList: metaDataListReducer
  }
});
