import React from 'react';
import {
  Box,
  Container,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Skeleton
} from '@mui/material';

const ContinentCardSkeleton = ({ isMobile, isTablet }) => {
  const getPadding = () => {
    if (isMobile) return 2;
    if (isTablet) return 10;
    return 1;
  };

  return (
    <Box className="visiting_places">
      <Container sx={{ paddingX: '0px !important' }}>
        <Grid container spacing={2} justifyContent="center">
          {[...Array(4)].map((_, i) => (
            <Grid
              item
              xs={3}
              sm={3}
              md={4}
              lg={3}
              key={i}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Card
                sx={{
                  display: { xs: 'flex' },
                  gap: { xs: '20px' },
                  width: '100%',
                  marginTop: 5,
                  overflow: 'unset'
                }}>
                <CardActionArea
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}>
                  <Skeleton
                    variant="rectangular"
                    width="95%"
                    height={isMobile ? '150px' : '250px'}
                    sx={{ borderRadius: 2, marginTop: '-12px' }}
                  />
                  <CardContent
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: getPadding()
                    }}>
                    <Typography className="everyStep_card_placeName" color="textSecondary">
                      <Skeleton variant="text" width={80} height={30} />
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ContinentCardSkeleton;
