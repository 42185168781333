import React, { useEffect, useState } from 'react';
import { CloseRounded } from '@mui/icons-material';
import './WhatsAppWidget.css';

const WhatsAppWidget = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [iconSize, setIconSize] = useState('52px');

  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth <= 768 ? '45px' : '50px');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const brand = 'Engati';
    const brandSpan = document.getElementById('brandSpan');
    if (brandSpan) {
      brandSpan.innerHTML = '';
      if (brand === 'Engati') {
        const engatiLink = document.createElement('a');
        engatiLink.href =
          'https://www.engati.com/ecommerce-chatbots?utm_source=shopify&utm_medium=web_widget&utm_campaign=shopify_widget';
        engatiLink.target = '_blank';
        engatiLink.rel = 'noopener noreferrer';
        engatiLink.title = 'Engati.com';
        engatiLink.style.color = '#403f42';
        engatiLink.textContent = 'Engati.com';
        const textNode = document.createElement('div');
        textNode.textContent = 'Powered by ';
        textNode.appendChild(engatiLink);
        brandSpan.appendChild(textNode);
      } else {
        brandSpan.textContent = `Powered by ${brand}`;
      }
    }
  }, []);

  const togglePopup = () => setIsPopupVisible((prev) => !prev);

  return (
    <div id="whatsAppWidgetSpan">
      <div
        id="popupBox"
        style={{ display: isPopupVisible ? 'contents' : 'none', marginBottom: '8px' }}>
        <span className="whatsApp-crossBtn" id="crossBtn" onClick={togglePopup}>
          <CloseRounded fontSize="small" />
        </span>
        <div className="whatsApp-waPopupBox" id="waPopupBox">
          <div className="whatsApp-waPopupBoxHeading" id="waPopupBoxHeading">
            <b>Hi,</b>
          </div>
          <p className="whatsApp-waPopupBoxBody" id="waPopupBoxBody">
            Greetings for the day! We are here to help you. Contact us for any support.
          </p>
          <a
            className="whatsApp-startBtn"
            id="startBtn"
            href="https://wa.me/918657758850?text=Hi"
            target="_blank"
            rel="noopener noreferrer">
            Start Chat
          </a>
          <div>
            <span className="whatsApp-brandSpan" id="brandSpan" />
          </div>
        </div>
      </div>
      <div
        className="whatsApp-WidgetIcon"
        id="whatsAppWidgetIcon"
        onClick={togglePopup}
        style={{
          width: iconSize,
          height: iconSize
        }}>
        <img
          src="https://branding-resources.s3.ap-south-1.amazonaws.com/bot_files/prod/whatsapp_icon.png"
          id="waWidgetIcon"
          alt="WhatsApp Icon"
          style={{ width: iconSize, height: iconSize }}
        />
      </div>
    </div>
  );
};

export default WhatsAppWidget;
