import React from 'react';
import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { CloseRounded, PlayArrow } from '@mui/icons-material';

const VideoModal = ({ open, handleClose }) => (
  <Box>
    <Button variant="contained" color="primary" onClick={open}>
      <PlayArrow />
    </Button>
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      disableBackdropClick
      aria-labelledby="video-dialog-title">
      <Box
        className="Hiren"
        position="absolute"
        top="-5px"
        right="-5px"
        bgcolor="white"
        borderRadius="50%"
        zIndex="1000">
        <IconButton onClick={handleClose} aria-label="close">
          <CloseRounded />
        </IconButton>
      </Box>

      <DialogContent sx={{ padding: 0 }}>
        <video
          src={`${process.env.PUBLIC_URL}/assets/VideoTutorial/Untitled-design-2.mp4`}
          controls
          autoPlay
          style={{ width: '100%' }}>
          Your browser does not support the video tag.
        </video>
      </DialogContent>
    </Dialog>
  </Box>
);

export default VideoModal;
