import React, { useEffect, useState } from 'react';
import './KesariSelectReview.css';
import { Avatar, Box, CardHeader, Grid, Paper, Rating, styled, Typography } from '@mui/material';
import { Masonry } from '@mui/lab';
import reviwer from '../../../assets/Home/kesari_review/reviwer.webp';
import { useNavigate } from 'react-router-dom';
import { API_URL, BASE_URL } from '../../../APIs/endpoint';
import Loader from '../../Loader/loader';
import { api } from '../../../APIs/api';
import { toast } from 'react-toastify';
import CustomButton from '../../common/Button';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'left',
  color: theme.palette.text.secondary
}));

const KesariSelectReview = () => {
  const [reviewData, setReviewData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  const getReviewListApi = async () => {
    try {
      setIsLoader(true);
      const reviewRes = await api.get(`${BASE_URL}${API_URL.REVIEW_LIST}`);
      if (reviewRes.status) {
        const updatedReviewData = reviewRes.data.map((item) => ({
          ...item,
          img: reviwer,
          review_rating: parseFloat(item.review_rating)
        }));
        setReviewData(updatedReviewData);
      } else {
        toast.error(reviewRes.data.message, { autoClose: 3000 });
      }
    } catch (err) {
      toast.error(err.message, { autoClose: 3000 });
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getReviewListApi();
  }, []);

  return (
    <>
      <Box className="kesariSelect_main">
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <Box
            sx={{
              width: '100%',
              minHeight: 393,
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Grid
              container
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Grid
                xs={12}
                sm={12}
                md={8}
                lg={11}
                xl={12}
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                <p className="kesariSelect_main_heading">Kesari Select Reviews</p>
                <div className="effect-kesari-select-review-container">
                  <div
                    style={{
                      display: 'flex',
                      zIndex: '1',
                      overflow: 'hidden',
                      minWidth: '80vw'
                    }}>
                    {isLoader ? (
                      <Grid container item xs={12} justifyContent="center" sx={{ display: 'flex' }}>
                        <Loader />
                      </Grid>
                    ) : reviewData.length === 0 ? (
                      <Box textAlign="center">
                        <Typography variant="h5" className="erp-no-data-found-text">
                          'Coming Soon...!'
                        </Typography>
                      </Box>
                    ) : (
                      <Masonry
                        columns={3}
                        spacing={2}
                        defaultHeight={'auto'}
                        defaultColumns={3}
                        defaultSpacing={1}
                        sx={{
                          zIndex: '1 !important'
                        }}>
                        {reviewData?.map((item, index) => (
                          <Item
                            key={index}
                            sx={{
                              padding: '33px 44px',
                              borderRadius: '16px',
                              boxShadow: '3px 3px 20px 0px rgba(0, 0, 0, 0.08)'
                            }}>
                            <Rating
                              name={`customized-${index}`}
                              value={item.review_rating}
                              precision={0.5}
                              max={5}
                              readOnly
                              sx={{ textAlign: 'left' }}
                            />
                            <p className="kesariSelect_card" color="text.secondary">
                              {item.review_description}
                            </p>
                            <CardHeader
                              sx={{ padding: '8px 0px' }}
                              avatar={
                                <Avatar
                                  sx={{ width: '100%', textAlign: 'left' }}
                                  aria-label="recipe"
                                  src={item.img}>
                                  R
                                </Avatar>
                              }
                              title={item.review_username}
                              subheader={new Date(item.review_date).toLocaleDateString('en-US', {
                                month: 'short',
                                year: 'numeric'
                              })}
                            />
                          </Item>
                        ))}
                      </Masonry>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="kesariSelect_btn_container">
          <CustomButton
            className="kesariSelect_typo_btn"
            onClick={() => navigate('/product-review')}
            showArrow={true}>
            <Typography fontFamily="gothamBold">See More Reviews</Typography>
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};

export default KesariSelectReview;
