import Indonesia_kesari from '../assets/Home/Slider/Indonesia_kesari.webp';
import kerala_kesari from '../assets/Home/Slider/kerala_kesari.webp';
import japan_kesari from '../assets/Home/Slider/japan_kesari.webp';
import leh_ladakh_kesari from '../assets/Home/Slider/leh_ladakh_kesari.webp';

export const prefectWeekendSlides = [
  {
    slide_title: 'INDONESIA',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: Indonesia_kesari
  },
  {
    slide_title: 'KERALA',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: kerala_kesari
  },
  {
    slide_title: 'JAPAN',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: japan_kesari
  },
  {
    slide_title: 'LEH LADAKH',
    slide_desc:
      'Discover the enchanting beauty of Indonesia, a diverse archipelago where lush rainforests, pristine beaches, and vibrant cultures converge, creating unforgettable tropical getaway.',
    img: leh_ladakh_kesari
  }
];
