/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Typography,
  Link
} from '@mui/material';

const TripRequestConfirmation = () => {
  const tripDetailsFromStorage = JSON.parse(sessionStorage.getItem('tripDetails'));

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const tripDetails = {
    'Package Id:':
      tripDetailsFromStorage?.packageId === 'N/A' ? 'N/A' : tripDetailsFromStorage?.packageId,
    'Destination:':
      tripDetailsFromStorage?.packageId === 'N/A' ? 'N/A' : tripDetailsFromStorage?.countryName,
    'Departure Date:':
      tripDetailsFromStorage?.tripDate === 'N/A'
        ? 'N/A'
        : new Date(tripDetailsFromStorage?.tripDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
    "I'm flexible on the date:": 'Yes',
    'Number of nights:': tripDetailsFromStorage?.vacationDays,
    'Number of Travellers:': `${tripDetailsFromStorage?.tripMembers?.adults} Adults, ${tripDetailsFromStorage?.tripMembers?.children} Children, ${tripDetailsFromStorage?.tripMembers?.infants} Infants`,
    'Travelling with children:':
      tripDetailsFromStorage?.tripMembers?.infants + tripDetailsFromStorage?.tripMembers?.children >
      0
        ? 'Yes'
        : 'No',
    // 'Age Group of Adults:': '18-30',
    'Is Budget flexible:': 'Taking the perfect trip is most important',
    'Type of travel:': 'Custom Trip Package'
  };

  // const handlePrint = () => {
  //   const printContent = document.querySelector('.confirm-modal');
  //   const printWindow = window.open('', '_blank');
  //   const styles = document.head.querySelectorAll("style, link[rel='stylesheet']");

  //   printWindow.document.write('<html><head><title>Print</title>');
  //   styles.forEach((style) => printWindow.document.write(style.outerHTML));
  //   printWindow.document.write('</head><body>');
  //   printWindow.document.write(printContent.outerHTML);
  //   printWindow.document.write('</body></html>');

  //   printWindow.document.close();
  //   printWindow.focus();
  //   printWindow.print();
  //   printWindow.close();
  // };

  return (
    <Box bgcolor="#f5f5f5" width="100%" height="60vh" display="grid" sx={{ placeItems: 'center' }}>
      <Box
        sx={{
          p: 2,
          bgcolor: '#fff',
          textAlign: 'center',
          boxShadow: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxHeight: '90vh',
          overflowY: 'auto'
        }}
        className="confirm-modal">
        <Typography fontFamily="gothamBold" fontSize={30} margin={0}>
          Thank you for your enquiry.
        </Typography>
        <Typography fontFamily="gothamBook" fontSize={18}>
          We will get in touch with you shortly.
        </Typography>
        {/* <Typography fontFamily="gothamBook" fontSize={18} mt={1}>
          Trip Request ID:
        </Typography>
        <Typography fontFamily="gothamBook" fontSize={16} margin={0}>
          BSD2QWS1WSCX
        </Typography> */}

        {/* <TableContainer component={Paper} sx={{ mt: 2, maxHeight: 400, overflowY: 'auto' }}>
          <Table>
            <TableBody>
              {Object.entries(tripDetails).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      borderBottom: 'none',
                      padding: '12px',
                      maxWidth: '141px',
                      wordWrap: 'break-word'
                    }}
                    component="th"
                    scope="row">
                    {key}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none', padding: '12px' }}>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
        <Button
          sx={{
            bgcolor: '#ed9300',
            color: '#fff',
            px: 4,
            py: 1.5,
            mt: 2,
            fontFamily: 'gothamBold',
            textTransform: 'capitalize',
            ':hover': {
              bgcolor: '#ed9300',
              color: '#fff'
            }
          }}
          variant="contained"
          onClick={() => (window.location.href = `${window.location.origin}/explore`)}>
          {/* onClick={handlePrint}> */}
          Explore more trip
        </Button>
        <Typography mt={2}>
          Need help? Contact{' '}
          <Link href="mailto:holiday@kesariselect.in">holiday@kesariselect.in</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default TripRequestConfirmation;
