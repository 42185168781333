import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import NotFoundImage from '../assets/Images/404.webp';
import CustomButton from '../components/common/Button';

const NotFound404 = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      textAlign="center"
      sx={{ padding: 4 }}>
      <Box
        component="img"
        src={NotFoundImage}
        alt="Page not found"
        sx={{
          width: '100%',
          maxWidth: 400,
          height: 'auto',
          mb: 2
        }}
      />
      <CustomButton
        className="PerfectWeekend_typo_btn"
        onClick={() => navigate('/')}
        showArrow={true}>
        <Typography fontFamily="gothamBold">Go to Home</Typography>
      </CustomButton>
    </Box>
  );
};

export default NotFound404;
